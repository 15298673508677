import * as React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { forMidDesktop, isDesktop } from '@westwing/ui-kit/breakPoints';
import HorizontalScrollableElement, { ScrollDirection } from '../ScrollableElement';
import { StateInterface } from '../../redux/types';
import { GenericPromotion, ProductInterface, WishlistTrackingFn } from '../../redux/products/types';
import useGa from '../../hooks/useGa';
import useBellaTranslator from '../../hooks/useTranslator';
import ProductSlider from '../Generic/Sliders/ProductSlider';
import GenericImpressionWrapper from '../Generic/GenericImpressionWrapper';
import { useSegmentProductClickTracking } from '../../../common/segment/useSegmentProductClickTracking';
import { useSegmentProductListViewedTracking } from '../../../common/segment/useSegmentProductListViewedTracking';
import { SEGMENT_TRACKING_SOURCES } from '../../../common/segment/types';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';
import { getSegmentProductPayload } from 'Client/components/ListingPage';

const BestSellerProductsSliderWrapper = styled(HorizontalScrollableElement)`
    color: ${({ theme }) => theme.colors.charcoal100};
    padding-top: 30px;
    padding-left: 40px;

    ${forMidDesktop`
        padding-left: 13px;
    `}
`;

const StyledProductSlider = styled(ProductSlider)`
    padding: 0 10px 0 40px;

    ${forMidDesktop`
        padding: 0;
    `}
`;

const defaultSegmentData: FeatureImpressionTracking = {
    feature_category: SegmentFeatureCategory.Slider,
    feature_name: 'Bestseller',
};

const BestSellerProductsSlider = () => {
    const t = useBellaTranslator();
    const gaTracking = useGa();
    const trackSegmentProductClick = useSegmentProductClickTracking(SEGMENT_TRACKING_SOURCES.BESTSELLER_SLIDER);
    const trackSegmentListingPageView = useSegmentProductListViewedTracking(SEGMENT_TRACKING_SOURCES.BESTSELLER_SLIDER);
    const { trackFeatureInteractions, trackFeatureImpression } = useSegmentFeatureTracking();

    const bestSellersProducts = useSelector<StateInterface, ProductInterface[]>(
        state => state.bestSellerProducts.products
    );
    const bestSellerPromotions = useSelector<StateInterface, GenericPromotion[]>(
        state => state.bestSellerProducts.promotions || []
    );

    const clickTrackingFn = React.useCallback(
        (product: ProductInterface) => {
            trackSegmentProductClick(product);

            gaTracking.homepage.trackNewProductSliderClick();
        },
        [gaTracking, trackSegmentProductClick]
    );

    const promotionClickTrackingFn = React.useCallback(
        (trackingName: string) => () => {
            gaTracking.homepage.trackPromotionalInfusionClick(trackingName);
            trackFeatureInteractions({
                ...defaultSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: 'Westwing Studio',
            });
        },
        [gaTracking, trackFeatureInteractions]
    );

    const wishListTrackingFn: WishlistTrackingFn = React.useCallback(
        ({ action, sku }) => {
            gaTracking.trackWishListHeartClick({
                action,
                label: `Bestseller_slider,${sku}`,
                sku,
                clickSource: 'Bestseller Slider',
            });
        },
        [gaTracking]
    );

    const bestSellerProductsWithTracking = React.useMemo(
        () =>
            bestSellersProducts.map(product => ({
                ...product,
                clickTrackingFn: () => clickTrackingFn(product),
                wishListTrackingFn,
            })),
        [bestSellersProducts, clickTrackingFn, wishListTrackingFn]
    );

    const promotionsWithTracking = bestSellerPromotions.map(promotion => ({
        ...promotion,
        trackingFn: promotionClickTrackingFn(promotion.trackingName),
    }));

    const sliderTrackLabel = 'Best_sellers';
    const trackArrowClicks = (direction: ScrollDirection) => {
        if (isDesktop()) {
            gaTracking.homepage.trackSliderArrowComponentClick(sliderTrackLabel);
            trackFeatureInteractions({
                ...defaultSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: direction === Direction.Left ? 'Left Arrow' : 'Right Arrow',
            });
        }
    };

    const impressionAction = React.useCallback(() => {
        trackSegmentListingPageView({
            products: bestSellersProducts.map(getSegmentProductPayload),
        });
        gaTracking.homepage.trackSliderImpression('Best sellers slider impression');

        trackFeatureImpression(defaultSegmentData);
    }, [gaTracking.homepage, trackSegmentListingPageView, trackFeatureImpression, bestSellersProducts]);

    return (
        <GenericImpressionWrapper action={impressionAction}>
            <BestSellerProductsSliderWrapper
                title={t('Bestsellers')}
                arrowClickTrackFunction={trackArrowClicks}
                showNavArrows
                showHeader
            >
                <StyledProductSlider products={bestSellerProductsWithTracking} promotions={promotionsWithTracking} />
            </BestSellerProductsSliderWrapper>
        </GenericImpressionWrapper>
    );
};
export default BestSellerProductsSlider;
