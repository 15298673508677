import React from 'react';
import styled from 'styled-components';
import { forDesktop, forMidDesktop, forMidWideScreens, forWideScreens, isDesktop } from '@westwing/ui-kit/breakPoints';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { BannerText } from './SecondaryBanner';
import Banner from 'Client/components/Generic/Banner';
import { CategorySlider } from 'Client/redux/homepage/types';
import { categorySliderRatios } from 'Client/constants/aspectRatios';
import HorizontalScrollableElement, { ScrollDirection } from 'Client/components/ScrollableElement';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { HorizontalScrollContainer } from 'Client/components/Generic/ProductsGrid/Infusions';
import useGa from 'Client/hooks/useGa';
import GenericImpressionWrapper from 'Client/components/Generic/GenericImpressionWrapper';
import { BellaTestIds } from 'Common/qaIds';
import { sliderContainerStyles } from 'Client/components/Generic/ContentInfusionsSlider/SliderTile';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import { changePreformattedImageProperties } from '@westwing/ui-kit/utils/image';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

interface CategorySliderProps {
    categories: CategorySlider[];
}

export const CategorySliderStyledContainer = styled.div`
    ${sliderContainerStyles}
    ${forMidWideScreens`
        flex-basis: 303px;
        &:last-child {
            flex-basis: 303px;
            padding-right: 0;
        }
    `}
`;

const SliderWrapper = styled(HorizontalScrollableElement)`
    padding-left: 40px;
    padding-top: 30px;

    ${forMidDesktop`
        padding-left: 13px;
    `}
`;

const StyledHorizontalScrollContainer = styled(HorizontalScrollContainer)`
    padding: 0 10px 0 40px;

    ${forMidDesktop`
        padding: 0;
    `}
`;

const StyledBannerText = styled(BannerText)`
    padding-top: 5px;
    max-width: 175px;
    ${forDesktop`
        max-width: 212px;
    `}
    ${forWideScreens`
        max-width: 303px;
    `}
`;

const StyledBanner = styled(Banner)`
    ${AspectRatio}
`;

const sliderTrackingLabel = 'Category_slider';
const defaultSegmentData: FeatureImpressionTracking = {
    feature_category: SegmentFeatureCategory.Slider,
    feature_name: 'Categories',
};

const CategorySliders: React.FC<CategorySliderProps> = ({ categories = [] }) => {
    const logger = useLogger();
    const t = useBellaTranslator();
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const addTracking = (
        cat: CategorySlider,
        i: number
    ): { banner: CategorySlider; trackingFn: (label: string) => void; trackingSegmentFn: () => void } => ({
        banner: cat,
        trackingFn: () => gaTracking.homepage.trackCategorySliderTrackingClick(cat.trackingName, i),
        trackingSegmentFn: () =>
            trackFeatureInteractions({
                ...defaultSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: `${cat.title} ${i}`,
            }),
    });

    const trackArrowClicks = (direction: ScrollDirection) => {
        if (isDesktop()) {
            gaTracking.homepage.trackSliderArrowComponentClick(sliderTrackingLabel);
            trackFeatureInteractions({
                ...defaultSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: direction === Direction.Left ? 'Left Arrow' : 'Right Arrow',
            });
        }
    };
    const handleBannerImpression = (trackingName: string) => {
        gaTracking.homepage.trackBannerImpression(trackingName);
    };

    const handleSliderImpression = () => {
        gaTracking.homepage.trackSliderImpression('Categories slider impression');
        trackFeatureImpression(defaultSegmentData);
    };

    const categoriesWithTracking = categories.map(addTracking);

    const onImageError = (e: Error) => {
        logger.warn(e.message);
    };

    return (
        <GenericImpressionWrapper action={handleSliderImpression}>
            <SliderWrapper
                title={t('homepage_topcategory')}
                arrowClickTrackFunction={trackArrowClicks}
                showHeader
                showNavArrows
                centerOffset={100}
            >
                <StyledHorizontalScrollContainer>
                    {categoriesWithTracking.map(({ banner, trackingFn, trackingSegmentFn }) => {
                        const formattedCmsForMobile = changePreformattedImageProperties(
                            banner.image,
                            {
                                height: '606',
                            },
                            onImageError
                        );
                        return (
                            <CategorySliderStyledContainer
                                key={banner.title}
                                data-testid={BellaTestIds.homepageCategorySlider}
                            >
                                <StyledBanner
                                    ratio={categorySliderRatios}
                                    desktopImage={banner.image}
                                    mobileImage={formattedCmsForMobile}
                                    targetUrl={banner.url}
                                    trackingFn={() => {
                                        trackingFn(banner.title);
                                        trackingSegmentFn();
                                    }}
                                    trackImpressionFunction={() => handleBannerImpression(banner.trackingName)}
                                />
                                <StyledBannerText
                                    {...banner}
                                    trackingSegmentFn={() => trackingSegmentFn()}
                                    targetUrl={banner.url}
                                    trackingName="todo"
                                />
                            </CategorySliderStyledContainer>
                        );
                    })}
                </StyledHorizontalScrollContainer>
            </SliderWrapper>
        </GenericImpressionWrapper>
    );
};

export default CategorySliders;
