import styled, { css } from 'styled-components';
import { forWideScreens, forMidDesktop, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import { AspectRatio, Ratios } from '@westwing/ui-kit/AspectRatio';
import LineSeparator from '../Generic/LineSeparator';
import { BannerText, ImgProps, TextProps, SecondaryBanner } from './SecondaryBanner';

export const PlacingAndSecondaryBannerWrapper = styled.div`
    display: grid;
    margin: 0 20px;
    grid-template:
        'placing    ' minmax(247px, max-content)
        'refer-friend ' auto
        '.            ' 20px
        'secondary    ' auto
        'text         ' auto
        / 100%;
    ${forMidDesktop`
        margin: 0 40px;
        grid-template:
            'secondary     .             placing    ' auto
            'text          text          text         ' auto
            'refer-friend  refer-friend  refer-friend ' auto
            / 1fr 10px 1fr;
    `}
`;

export const PermanentBannersWrapper = styled.div`
    ${forMidDesktop`
        margin: 0 20px;
    `}
`;

export const StyledSecondaryBanner = styled(SecondaryBanner)<ImgProps & { ratio: Ratios }>`
    ${AspectRatio}
    grid-area: secondary;
`;

export const StyledBannerText = styled(BannerText)<TextProps>`
    grid-area: text;

    ${forMidDesktop`
        max-width: 45%;
    `}
`;

export const PlacingBannersWrapper = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 5px;
    ${forMidDesktop`
        grid-area: placing;
        display: flex;
        flex-basis: auto;
        flex-flow: column;
        justify-content: space-between;
    `}
`;

export const ProductsScrollWrapper = styled.div`
    margin: 0 0 40px 0;
    ${({ theme }) =>
        !theme.featureToggles.p100FreistellerImages &&
        css`
            height: 410px;

            ${forMidDesktop`
                height: 459px;
            `}

            ${forWideScreens`
                height: 609px;
            `}
        `}

    ${forMidDesktop`
        margin: 0 27px 40px;
    `}

    ${forWideScreens`
        height: 609px;
    `}

    ${forMidWideScreens`
            height: auto;
            margin-bottom: 60px;
    `}
`;

export const ContentWrapper = styled.div`
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;

    ${forMidDesktop`
        padding: 40px 0 0 0;
    `}
`;

export const StyledLineSeparator = styled(LineSeparator)`
    width: calc(100% - 20px);
    margin-left: 20px;
    ${forMidDesktop`
        width: calc(100% - 40px);
    `}

    padding-bottom: 10px;
`;

export const StyledSliderLineSeparator = styled(StyledLineSeparator)`
    ${forMidDesktop`
        margin: 0 13px;
        width: calc(100% - 26px);
    `}
`;

export const StyledSingleLookPromotionLineSeparator = styled(StyledLineSeparator)`
    ${forMidDesktop`
        margin: 0 40px;
        width: calc(100% - 80px);
    `}
`;

export const StyledBannersLineSeparator = styled(StyledLineSeparator)`
    ${forMidDesktop`
        margin: 0 18px;
    `}
`;

export const StyledScrollContainer = styled.div`
    ${forMidDesktop`
        margin: 0 27px;
    `}
`;

export const StyledLookPromotionContainer = styled(StyledScrollContainer)`
    ${forMidDesktop`
        margin: 0 20px;
    `}
`;

export const UGCContainer = styled.div`
    ${forMidDesktop`
        padding: 0 20px;
    `}
`;

// Yes its an empty div but its IMPORTANT. It is needed to contain the PlacingBanners Component on Safari 10.1
export const Wrapper = styled.div``;

export const HomePageHeroBanner = styled.div`
    ${AspectRatio}
`;
