import React, { FC, ReactElement } from 'react';
import UIkitPicture, { jpegBackupSource, ImageFormat } from '@westwing/ui-kit/Picture';
import { IMAGE_WIDTHS } from '@westwing/ui-kit/utils/image';
import { contentfulWidthFormatter } from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';

type ThumbnailImageProps = { className?: string; src: string; alt: string };

export const ThumbnailImage: FC<ThumbnailImageProps> = ({ className, src, alt }): ReactElement => {
    const { webpSrc, jpegSrc } = contentfulWidthFormatter(src, IMAGE_WIDTHS.THUMBNAIL_LOW_DENSITY);
    const { webpSrc: webpSrc2x, jpegSrc: jpegSrc2x } = contentfulWidthFormatter(
        src,
        IMAGE_WIDTHS.THUMBNAIL_HIGH_DENSITY
    );

    const mobileSource2x = jpegBackupSource(
        {
            media: '',
            url: webpSrc2x,
            value: 2,
            format: ImageFormat.WEBP,
        },
        jpegSrc2x
    );

    const sources = [
        ...jpegBackupSource(
            {
                media: '',
                url: webpSrc,
                value: 1,
                format: ImageFormat.WEBP,
            },
            jpegSrc
        ),

        ...mobileSource2x,
    ];

    return (
        <UIkitPicture
            className={className}
            sources={sources}
            loading="eager"
            fallback={{
                url: mobileSource2x[1].url,
                className,
                alt,
            }}
        />
    );
};

export default ThumbnailImage;
