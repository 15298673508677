import React, { FC, ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationDescriptorObject } from 'history';
import { header1 } from '@westwing/ui-kit/typography';
import { isDesktop } from '@westwing/ui-kit/breakPoints';
import ErrorBoundary from '../Generic/ErrorBoundaries';
import GenericErrorRetry from '../Generic/ErrorPages/GenericErrorRetry';
import { fetchHomePageCmsDataIfNeeded } from '../../redux/homepage/action';
import StateInterface from '../../redux/types';
import { CrossCategoryNavigation as CrossCatNavInterface, ProductInterface } from '../../redux/products/types';
import { fetchBestSellerProducts, fetchNewProducts } from '../../redux/products/actions';
import useConfig from 'AppShell/hooks/useConfig';
import { getCategoriesUrl } from '../../urlService';
import { HomepageItems } from '../../redux/homepage/types';
import { SEO_CONSTANTS } from '../ListingPage/services/seo/constant';
import GenericSeoHead from '../Generic/SeoHead';
import { homePageheroBannerRatios, homePageSecondaryBannerRatios } from '../../constants/aspectRatios';
import { homePageHrefLang } from '../ListingPage/services/seo';
import { fetchHomePageSeoDataIfNeeded } from '../../redux/seo/actions';
import { SeoData } from '../../redux/seo/types';
import useRouter from 'AppShell/hooks/useRouter';
import useGa from '../../hooks/useGa';
import { TRACKING_PAGE_LABELS } from '../ListingPage/hooks/useTrackingPageLabel';
import { CategoryData } from '../../redux/category/types';
import PlacingBanners from './PlacingBanner';
import PermanentBanners from './PermanentBanner';
import CategorySliders from './CategorySlider';
import PortraitBanners from './PortraitBanners';
import HeroBanner from './HeroBanner';
import CategoriesBubbleSlider from './CategoriesBubbleSlider';
import LooksSliderPromotion from './LooksSliderPromotion';
import NewProductsSlider from './NewProductsSlider';
import UGGalleryBanner from './UGGalleryBanner';
import ReferAFriendBanner from './ReferAFriendBanner';
import BestSellerProductsSlider from './BestSellerProductsSlider';
import SingleLookPromotion from './SingleLookPromotion';
import {
    PlacingAndSecondaryBannerWrapper,
    PermanentBannersWrapper,
    StyledSecondaryBanner,
    StyledBannerText,
    PlacingBannersWrapper,
    ProductsScrollWrapper,
    ContentWrapper,
    StyledLineSeparator,
    StyledSliderLineSeparator,
    StyledSingleLookPromotionLineSeparator,
    StyledBannersLineSeparator,
    StyledScrollContainer,
    StyledLookPromotionContainer,
    UGCContainer,
    Wrapper,
    HomePageHeroBanner,
} from './HomepageElements';
import { BellaTestIds } from 'Common/qaIds';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import useLoginAndRegisterOverlayWithTracking from 'Client/hooks/useLoginAndRegisterOverlayWithTracking';
import { fetchLooksSliderIfNeeded } from 'Client/redux/looksSlider/actions';
import { SessionTypes } from 'AppShell/appshell_types';
import { PromotionBar } from 'Client/components/Homepage/PromotionBar';
import NavigationThumbnails from 'Client/components/Homepage/NavigationThumbnails';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { fetchHomePageContentIfNeeded } from 'Client/redux/homepageContent/actions';
import { useBellaSelector } from 'Client/redux/hooks';
import PriceDisclaimer from 'Client/components/Generic/PriceDisclaimer';
import useFetchingAction from 'Client/hooks/useFetchingAction/useFetchingAction';
import { combineThunks } from 'Client/redux/utils';

export const LOGIN_ON = '?login=on';

const shouldOpenLoginOverlay = (location: LocationDescriptorObject, isNotFullyLoggedIn: boolean): boolean => {
    const { search } = location;
    return search === LOGIN_ON && isNotFullyLoggedIn;
};

const NewProductsSliderComponent = () => (
    <ProductsScrollWrapper data-testid={BellaTestIds.homepageNewProducts}>
        <StyledSliderLineSeparator />
        <NewProductsSlider />
    </ProductsScrollWrapper>
);

const BestSellerProductsSliderComponent = () => (
    <ProductsScrollWrapper data-testid={BellaTestIds.homepageBestSellerProducts}>
        <StyledSliderLineSeparator />
        <BestSellerProductsSlider />
    </ProductsScrollWrapper>
);

// eslint-disable-next-line complexity
const HomePage: FC<{}> = (): ReactElement => {
    const newProductsPageSize = 10;
    const config = useConfig();
    const gaTracking = useGa();
    const dispatch = useDispatch();
    const {
        p100BestSellerInfusion,
        p100RevisedMobileHeaderPromotionBar,
        p100RevisedMobileHeaderCategoryThumbnail,
        p100SaleNavigation,
        p100AATest8,
        p100DataPrefetchExperiment,
        p100RemoveRaf,
    } = useFeatureToggle();
    const { showLoginAndRegisterOverlay, trackLoginOverlayShow } = useLoginAndRegisterOverlayWithTracking();
    const t = useBellaTranslator();

    const visibleOriginalCategories = useSelector<StateInterface, CategoryData[]>(
        state => state.categories.visibleOriginalCategories
    );
    const sessionType = useSelector<StateInterface, SessionTypes | undefined>(
        state => state.appShellLogin.data?.sessionType
    );
    const isNotFullyLoggedIn = sessionType !== SessionTypes.full;
    const homepageItems = useSelector<StateInterface, HomepageItems>(state => state.homepage.homepageItems);
    const homepageIdentifier = useSelector<StateInterface, string>(state => state.homepage.identifier);
    const homePageSeoData = useSelector<StateInterface, SeoData>(state => state.homePageSeoData.seoData);
    const { navigationThumbnailsContent, promotionBarContent, isFetched } = useBellaSelector(
        state => state.homepageContent
    );
    const newProducts = useSelector<StateInterface, ProductInterface[]>(state => state.newProducts.products);
    const bestSellersProducts = useSelector<StateInterface, ProductInterface[]>(
        state => state.bestSellerProducts.products
    );

    const firstLevelCategories: CrossCatNavInterface = {
        categories: visibleOriginalCategories.map(category => ({
            name: category.name,
            link: getCategoriesUrl(category.url),
            isSelected: false,
        })),
        parentCategory: { link: '', isRoot: true },
    };

    if (p100SaleNavigation) {
        firstLevelCategories.categories.push({
            name: t('Deals'),
            link: config.links.sales,
            isSelected: false,
        });
    }

    const {
        heroBanner,
        categoriesSlider,
        placingBanners,
        portraitBanners,
        secondaryBanner,
        ugcGalleryBanner,
        referAFriendBanner,
        permanentBanners,
        promotedLook,
    } = homepageItems;
    const { seoTitle, seoDescription, similarPages } = homePageSeoData;

    const hasDiscountedProductOnPage = useCallback(() => {
        const mergedProducts = [...newProducts, ...bestSellersProducts, ...(promotedLook?.products || [])];
        return mergedProducts.some((product: ProductInterface) => product.numericPrice < product.numericOriginalPrice);
    }, [newProducts, bestSellersProducts, promotedLook]);

    const {
        countryCode,
        currency,
        links: { url: host },
    } = config;

    /* useEffect is used here to avoid usage of useFetchingAction
    in case of p100DataPrefetchExperiment is enabled. Actual server-side data
    fetching is done in src/server/middleware/dataFetchingMiddleware.ts
     */
    useEffect(() => {
        if (p100DataPrefetchExperiment) {
            dispatch(fetchHomePageCmsDataIfNeeded({ countryCode, currency }));
            dispatch(fetchNewProducts(newProductsPageSize, { countryCode, currency }));
            dispatch(
                fetchBestSellerProducts(
                    newProductsPageSize,
                    { countryCode, currency },
                    { includePromotions: !!p100BestSellerInfusion }
                )
            );
            dispatch(fetchHomePageSeoDataIfNeeded());
            dispatch(fetchLooksSliderIfNeeded());

            if (p100RevisedMobileHeaderCategoryThumbnail || p100RevisedMobileHeaderPromotionBar) {
                dispatch(fetchHomePageContentIfNeeded());
            }
        }
    }, [homepageIdentifier]);

    const thunks = p100DataPrefetchExperiment
        ? undefined
        : [
              fetchHomePageCmsDataIfNeeded({ countryCode, currency }),
              fetchNewProducts(newProductsPageSize, { countryCode, currency }),
              fetchBestSellerProducts(
                  newProductsPageSize,
                  { countryCode, currency },
                  { includePromotions: !!p100BestSellerInfusion }
              ),
              fetchHomePageSeoDataIfNeeded(),
              fetchLooksSliderIfNeeded(),
          ];

    if (thunks && (p100RevisedMobileHeaderCategoryThumbnail || p100RevisedMobileHeaderPromotionBar)) {
        thunks.push(fetchHomePageContentIfNeeded());
    }

    useFetchingAction(thunks ? combineThunks(...thunks) : undefined);
    const { p100EnablePtHreflang } = useFeatureToggle();
    const hrefLangs = homePageHrefLang(config, p100EnablePtHreflang);
    const openGraphImage = heroBanner.mobileImage || heroBanner.desktopImage;
    const couldShowRevisedMobileHeaderPromotion = promotionBarContent.length > 0;

    const { location } = useRouter();
    const showLoginOverlay = shouldOpenLoginOverlay(location, isNotFullyLoggedIn);

    useEffect(() => {
        if (showLoginOverlay) {
            showLoginAndRegisterOverlay({ isLogin: true });
            trackLoginOverlayShow({ isLogin: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showLoginOverlay]);

    useEffect(() => {
        gaTracking.trackPageView({ pageType: TRACKING_PAGE_LABELS.HOME });
        // Only fire once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (couldShowRevisedMobileHeaderPromotion && !isDesktop()) {
            gaTracking.trackVariation('p100AATest8', p100AATest8);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couldShowRevisedMobileHeaderPromotion]);

    const showUgc = !!(ugcGalleryBanner && ugcGalleryBanner.mobileImage && ugcGalleryBanner.targetUrl);

    const bottomCategories = similarPages.length
        ? similarPages.map(item => ({
              name: item.similar_page_text,
              link: item.similar_page_link,
              isSelected: false,
          }))
        : firstLevelCategories.categories;

    return (
        <ErrorBoundary boundaryName="HomePage" fallbackComponent={<GenericErrorRetry />}>
            <div>
                <GenericSeoHead
                    pageTitle={seoTitle}
                    pageDescription={seoDescription}
                    robotsIndexAbleContent={SEO_CONSTANTS.indexable}
                    links={hrefLangs}
                    pageUrl={host}
                    config={config}
                    type={SEO_CONSTANTS.ogTypeHomeContentValue}
                    openGraphImage={openGraphImage}
                />
                {p100RevisedMobileHeaderCategoryThumbnail && navigationThumbnailsContent.length > 0 ? (
                    <NavigationThumbnails
                        navigationThumbnailsContent={navigationThumbnailsContent}
                        isFetched={isFetched}
                    />
                ) : (
                    <ErrorBoundary boundaryName="Homepage CrossCategoryNav" fallbackComponent={null}>
                        <CategoriesBubbleSlider categories={firstLevelCategories.categories} />
                    </ErrorBoundary>
                )}
                {p100RevisedMobileHeaderPromotionBar && couldShowRevisedMobileHeaderPromotion && (
                    <PromotionBar promotionBarContent={promotionBarContent} />
                )}
                <HomePageHeroBanner ratio={homePageheroBannerRatios} data-testid={BellaTestIds.homePageHeroBanner}>
                    <HeroBanner {...heroBanner} />
                </HomePageHeroBanner>
                <ContentWrapper data-testid={BellaTestIds.homePageContentWrapper}>
                    <Wrapper>
                        <PlacingAndSecondaryBannerWrapper>
                            <PlacingBannersWrapper data-testid={BellaTestIds.homePagePlacingBannersWrapper}>
                                <PlacingBanners placingBanners={placingBanners} />
                            </PlacingBannersWrapper>
                            <StyledSecondaryBanner {...secondaryBanner} ratio={homePageSecondaryBannerRatios} />

                            <StyledBannerText {...secondaryBanner} desktopTypography={header1} />
                            {!p100RemoveRaf && <ReferAFriendBanner referAFriendData={referAFriendBanner} />}
                        </PlacingAndSecondaryBannerWrapper>
                    </Wrapper>
                    {promotedLook && (
                        <>
                            <StyledLookPromotionContainer>
                                <StyledLineSeparator />
                                <SingleLookPromotion promotedLook={promotedLook} />
                            </StyledLookPromotionContainer>
                            <StyledSingleLookPromotionLineSeparator />
                        </>
                    )}
                    <LooksSliderPromotion hideSeparator />
                    <StyledScrollContainer>
                        <StyledSliderLineSeparator />
                        <CategorySliders categories={categoriesSlider} />
                    </StyledScrollContainer>
                    <NewProductsSliderComponent />
                    <PermanentBannersWrapper>
                        <StyledBannersLineSeparator />
                        <PortraitBanners portraitBanners={portraitBanners} />
                    </PermanentBannersWrapper>
                    {showUgc && (
                        <UGCContainer>
                            <StyledBannersLineSeparator />
                            <UGGalleryBanner ugc={ugcGalleryBanner} />
                        </UGCContainer>
                    )}
                    <BestSellerProductsSliderComponent />
                    {permanentBanners.length === 3 && (
                        <PermanentBannersWrapper>
                            <StyledBannersLineSeparator />
                            <PermanentBanners banners={permanentBanners} />
                        </PermanentBannersWrapper>
                    )}
                </ContentWrapper>
                <ErrorBoundary boundaryName="Homepage CrossCategoryNav Bottom" fallbackComponent={null}>
                    <CategoriesBubbleSlider categories={bottomCategories} isBottom />
                </ErrorBoundary>
                <PriceDisclaimer.Centered pageHasSpecialPrice={hasDiscountedProductOnPage()} />
            </div>
        </ErrorBoundary>
    );
};

export default HomePage;
