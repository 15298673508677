import React, { useCallback } from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import Banner from '../Generic/Banner';
import { ReferAFriendData } from '../../redux/homepage/types';
import useGa from '../../hooks/useGa';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

interface Props {
    referAFriendData: ReferAFriendData;
}

const StyledBanner = styled.div`
    grid-area: refer-friend;
    margin: 21px 0 0;
    overflow: hidden;

    ${forDesktop`
        margin: 10px 0 20px;
        height: 80px;
    `}
`;
const ReferAFriendBanner: React.FC<Props> = ({ referAFriendData }) => {
    const ga = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const trackImpression = useCallback(() => {
        ga.homepage.trackReferAFriendBannerImpression(referAFriendData.trackingName || '');

        trackFeatureImpression({
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: 'Refer a Friend',
            feature_label: '',
        });
    }, [ga.homepage, referAFriendData.trackingName, trackFeatureImpression]);

    const hasBanner = !!referAFriendData && (!!referAFriendData.desktopImage || !!referAFriendData.mobileImage);
    return hasBanner ? (
        <StyledBanner>
            <Banner
                trackingFn={() => {
                    trackFeatureInteractions({
                        interaction_type: SegmentFeatureInteractionType.Click,
                        interaction_label: '',
                        feature_category: SegmentFeatureCategory.Banner,
                        feature_name: 'Refer a Friend',
                        feature_label: '',
                    });
                    ga.homepage.trackReferAFriendBannerClick(referAFriendData.trackingName || '');
                }}
                trackImpressionFunction={trackImpression}
                mobileImage={referAFriendData.mobileImage}
                desktopImage={referAFriendData.desktopImage}
                targetUrl={referAFriendData.targetUrl}
                altText={referAFriendData.altText}
                tabletAsMobile={false}
            />
        </StyledBanner>
    ) : (
        <></>
    );
};

export default ReferAFriendBanner;
