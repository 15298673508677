import React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { textEllipsisCss } from '@westwing/ui-kit/typography';
import ThumbnailImage from './ThumbnailImage';
import { scroll } from '@westwing/ui-kit/utils';
import { BellaTestIds } from 'Common/qaIds';
import useGa from 'Client/hooks/useGa';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import { NavigationThumbnailsContent } from 'Client/redux/homepageContent/types';

const thumbnailStyle = css`
    border-radius: 50%;
    height: 72px;
    width: 72px;
    background-color: ${({ theme }) => theme.colors.component.productTileSurface};
`;

const Thumbnail = styled(ThumbnailImage)`
    ${thumbnailStyle}
    object-fit: cover;
`;

const SkeletonThumbnail = styled.div`
    ${thumbnailStyle}
    background-color: ${({ theme }) => theme.colors.skeleton01};
    margin-bottom: 16px;
`;

const Label = styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.charcoal100};
    align-self: center;
    line-height: 16px;
    max-width: 72px;
    ${textEllipsisCss}
`;

const ThumbnailWrapper = styled(BellaHybridLink)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 4px;
    text-decoration: none;
`;
const ThumbnailPanel = styled.div`
    :before,
    :after {
        content: '';
        flex: 0 0 8px;
    }

    ${forDesktop`
        display: none;
    `}

    display: flex;
    gap: 12px;
    padding: 16px 0;
    scroll-behavior: smooth;
    ${scroll('x', true)}
`;

const renderSkeleton = () =>
    Array.from(Array(8).keys()).map(num => (
        <ThumbnailWrapper key={num} as="div" data-testid="navigation-thumbnails-skeleton-tile">
            <SkeletonThumbnail />
            <Label />
        </ThumbnailWrapper>
    ));

type NavigationThumbnailsProps = {
    navigationThumbnailsContent: NavigationThumbnailsContent[];
    isFetched: boolean;
};
export const NavigationThumbnails = ({
    navigationThumbnailsContent,
    isFetched,
}: NavigationThumbnailsProps): React.ReactElement | null => {
    const ga = useGa();

    return (
        <ThumbnailPanel data-testid={BellaTestIds.navigationThumbnailsPanel}>
            {isFetched
                ? navigationThumbnailsContent.map(({ id, relativeUrl, image, title, trackingName }) => (
                      <ThumbnailWrapper
                          data-testid={BellaTestIds.navigationThumbnails}
                          key={id}
                          to={relativeUrl}
                          onClick={() => ga.homepage.trackNavigationThumbnailsClick(trackingName)}
                      >
                          <Thumbnail src={image.url} alt={image.description} />
                          <Label>{title}</Label>
                      </ThumbnailWrapper>
                  ))
                : renderSkeleton()}
        </ThumbnailPanel>
    );
};

export default NavigationThumbnails;
