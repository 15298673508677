import React, { useCallback } from 'react';
import { HeroBanner as HeroBannerTypes } from '../../redux/homepage/types';
import Banner, { BannerProps } from '../Generic/Banner';
import useGa from '../../hooks/useGa';
import { BannerTrackingVariations } from 'Common/ga/homepage';
import GenericImpressionWrapper from '../Generic/GenericImpressionWrapper';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const HeroBanner: React.FC<HeroBannerTypes> = ({ ...heroBanner }) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const trackImpression = useCallback(() => {
        gaTracking.homepage.trackBannerImpression(heroBanner.trackingName);
        trackFeatureImpression({
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: 'Homepage 1',
            feature_label: heroBanner.trackingName,
        });
    }, [gaTracking.homepage, heroBanner.trackingName, trackFeatureImpression]);

    let bannerProps: BannerProps = {
        desktopImage: heroBanner.desktopImage,
        mobileImage: heroBanner.mobileImage,
        altText: heroBanner.altText,
        trackingFn: () => {
            trackFeatureInteractions({
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: '',
                feature_category: SegmentFeatureCategory.Banner,
                feature_name: 'Homepage 1',
                feature_label: heroBanner.trackingName,
            });
            gaTracking.homepage.getBannerTrackingFn(BannerTrackingVariations.General, heroBanner.trackingName)();
        },
    };

    if (heroBanner.targetUrl) {
        bannerProps = { ...bannerProps, targetUrl: heroBanner.targetUrl };
    }

    return (
        <GenericImpressionWrapper action={trackImpression}>
            <Banner {...bannerProps} shouldBeEagerlyLoaded />
        </GenericImpressionWrapper>
    );
};

export default HeroBanner;
