import * as React from 'react';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import styled from 'styled-components';
import { BannerTrackingVariations } from 'Common/ga/homepage';
import { homePagePermanentBannerRatios } from '../../constants/aspectRatios';
import useGa from '../../hooks/useGa';
import { PermanentBanner } from '../../redux/homepage/types';
import Banner from '../Generic/Banner';
import GenericImpressionWrapper from '../Generic/GenericImpressionWrapper';
import { BannerText } from './SecondaryBanner';
import { useCallback } from 'react';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const OuterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0;
    ${forDesktop`
        padding: 20px 20px 0;
    `}
    justify-content: space-between;
`;

const Wrapper = styled(GenericImpressionWrapper)`
    width: 100%;
    ${forDesktop`
        /**
         * with three images there is 22px total of interior gaps
         * so device that over the three images and you get 8px each
         * need to shrink by to get the 22px of white space
        */
        max-width: calc(33% - 8px);
   `}
`;

const AspectRatioBanner = styled(Banner)`
    ${AspectRatio}
    display: block;

    ${forDesktop`
        display: none;
    `}
`;

const MobileBannerText = styled(BannerText)`
    display: block;
    ${forDesktop`
        display: none;
   `}
`;
const DesktopBannerText = styled(BannerText)`
    display: none;
    ${forDesktop`
        display:block;
   `}
`;

const DesktopBanner = styled(AspectRatioBanner)`
    display: none;

    ${forDesktop`
        display: block;
   `}
`;

interface PermanentBannerProps {
    banners: PermanentBanner[];
}

const PermanentBanners = ({ banners = [] }: PermanentBannerProps) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const trackingFns = banners.map((banner, index) => () => {
        trackFeatureInteractions({
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: '',
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: `Promotional ${index + 1}`,
            feature_label: banner.trackingName,
        });
        gaTracking.homepage.getBannerTrackingFn(BannerTrackingVariations.General, banner.trackingName)();
    });

    const handleBannerImpression = useCallback(
        (trackingName: string, bannerNumber) => {
            gaTracking.homepage.trackBannerImpression(trackingName);

            trackFeatureImpression({
                feature_category: SegmentFeatureCategory.Banner,
                feature_name: `Promotional ${bannerNumber + 1}`,
                feature_label: trackingName,
            });
        },
        [gaTracking.homepage, trackFeatureImpression]
    );

    return (
        <OuterWrapper>
            {banners.map((banner, i) => (
                <Wrapper key={banner.trackingName} action={() => handleBannerImpression(banner.trackingName, i)}>
                    <AspectRatioBanner
                        ratio={homePagePermanentBannerRatios}
                        trackingFn={trackingFns[i]}
                        mobileImage=""
                        desktopImage={banner.image}
                        targetUrl={banner.targetUrlMobile}
                    />
                    <DesktopBanner
                        ratio={homePagePermanentBannerRatios}
                        trackingFn={trackingFns[i]}
                        mobileImage=""
                        desktopImage={banner.image}
                        targetUrl={banner.targetUrlDesktop}
                        openInNewTab={banner.openInNewTab}
                    />
                    <MobileBannerText {...banner} targetUrl={banner.targetUrlMobile} />
                    <DesktopBannerText {...banner} targetUrl={banner.targetUrlDesktop} />
                </Wrapper>
            ))}
        </OuterWrapper>
    );
};

export default PermanentBanners;
