import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { PlacingBanner } from '../../redux/homepage/types';
import Banner from '../Generic/Banner';
import { homePagePlacingBannersRatios } from '../../constants/aspectRatios';
import useGa from '../../hooks/useGa';
import { BannerTrackingVariations } from 'Common/ga/homepage';
import GenericImpressionWrapper from '../Generic/GenericImpressionWrapper';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const StyledBannerImpressionWrapper = styled(GenericImpressionWrapper)`
    ${AspectRatio}
`;

interface PlacingBannersProps {
    placingBanners: PlacingBanner[];
}

const PlacingBanners: React.FC<PlacingBannersProps> = ({ placingBanners = [] }) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const trackImpression = useCallback(
        (gaTrackingName, segmentTrackingName: string) => {
            gaTracking.homepage.trackBannerImpression(gaTrackingName);

            trackFeatureImpression({
                feature_category: SegmentFeatureCategory.Banner,
                feature_name: segmentTrackingName,
                feature_label: '',
            });
        },
        [gaTracking.homepage, trackFeatureImpression]
    );

    const bannersWithTracking = placingBanners.reduce<Array<[PlacingBanner, () => void, string]>>(
        (bannerPairs, banner) => {
            // first element of the array is supposed to be the westwing collection banner
            if (bannerPairs.length === 0) {
                bannerPairs.push([
                    banner,
                    () => {
                        trackFeatureInteractions({
                            interaction_type: SegmentFeatureInteractionType.Click,
                            interaction_label: '',
                            feature_category: SegmentFeatureCategory.Banner,
                            feature_name: 'FB Westwing Collection',
                            feature_label: '',
                        });
                        gaTracking.homepage.getBannerTrackingFn(
                            BannerTrackingVariations.WestwingCollection,
                            banner.trackingName
                        )();
                    },
                    'FB Westwing Collection',
                ]);
                // second element of the array is supposed to be the Westwing Studio banner
            } else if (bannerPairs.length === 1) {
                bannerPairs.push([
                    banner,
                    () => {
                        trackFeatureInteractions({
                            interaction_type: SegmentFeatureInteractionType.Click,
                            interaction_label: '',
                            feature_category: SegmentFeatureCategory.Banner,
                            feature_name: 'FB Westwing Studio',
                            feature_label: '',
                        });
                        gaTracking.homepage.getBannerTrackingFn(
                            BannerTrackingVariations.InteriorDesignService,
                            banner.trackingName
                        )();
                    },
                    'FB Westwing Studio',
                ]);
            }
            return bannerPairs;
        },
        []
    );

    return (
        <>
            {bannersWithTracking.map(([banner, trackingFn, segmentTrackingName]) => (
                <StyledBannerImpressionWrapper
                    key={banner.trackingName}
                    action={() => trackImpression(banner.trackingName, segmentTrackingName)}
                    ratio={homePagePlacingBannersRatios}
                >
                    <Banner
                        trackingFn={trackingFn}
                        mobileImage={banner.image}
                        desktopImage={banner.image}
                        targetUrl={banner.targetUrl}
                        shouldBeEagerlyLoaded
                    />
                </StyledBannerImpressionWrapper>
            ))}
        </>
    );
};

export default PlacingBanners;
