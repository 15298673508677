import React, { useCallback } from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { UGCBanner } from '../../redux/homepage/types';
import Banner from '../Generic/Banner';
import useGa from '../../hooks/useGa';
import { homePageUGCBannerRatios } from '../../constants/aspectRatios';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const Wrapper = styled.div`
    padding: 10px 20px 20px;
    ${forDesktop`
        padding: 30px 20px 40px;
    `}
`;

const StyledBanner = styled(Banner)`
    ${AspectRatio}
`;

const UGGalleryBanner: React.FC<{ ugc: UGCBanner }> = ({ ugc }) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const { mobileImage, desktopImage, altText, targetUrl, trackingName } = ugc;
    const trackClickAction = () => {
        trackFeatureInteractions({
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: '',
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: 'User Generated Content',
            feature_label: '',
        });
        if (trackingName) {
            gaTracking.homepage.trackUGCClick(trackingName);
        }
    };

    const trackImpression = useCallback(() => {
        trackFeatureImpression({
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: 'User Generated Content',
            feature_label: '',
        });
    }, [trackFeatureImpression]);

    return (
        <Wrapper>
            <StyledBanner
                trackImpressionFunction={trackImpression}
                ratio={homePageUGCBannerRatios}
                desktopImage={desktopImage}
                mobileImage={mobileImage}
                targetUrl={targetUrl}
                altText={altText}
                trackingFn={trackClickAction}
            />
        </Wrapper>
    );
};

export default UGGalleryBanner;
