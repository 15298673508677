import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, underMobileSmall } from '@westwing/ui-kit/breakPoints';
import { caption1 } from '@westwing/ui-kit/typography';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import { BellaTestIds } from 'Common/qaIds';
import useGa from 'Client/hooks/useGa';
import { PromotionBarContent } from 'Client/redux/homepageContent/types';

const sharedPromoStyles = css`
    ${caption1}
    color: ${({ theme }) => theme.colors.charcoal100};
    text-align: center;
    &:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.colors.charcoal20};
    }
    height: 17px;
`;

const PromoBarWrapper = styled.div<{ $itemsAmount: number }>`
    padding: 10px;
    display: grid;
    background: ${({ theme }) => theme.colors.turquoise20};
    grid-template-columns: ${({ $itemsAmount }) => `repeat(${$itemsAmount}, auto)`};
    align-content: center;
    ${forDesktop`
        display: none;
    `}
`;

const PromoBarLink = styled(BellaHybridLink)`
    ${sharedPromoStyles}
`;

const ShortTitleWrapper = styled.span<{ $hideIndependentlyOnResolution: boolean }>`
    display: none;
    ${({ $hideIndependentlyOnResolution }) =>
        !$hideIndependentlyOnResolution
            ? css`
                  ${underMobileSmall`
                display: inline;
            `}
              `
            : ''}
`;

const LongTitleWrapper = styled.span<{ $showIndependentlyOnResolution: boolean }>`
    ${({ $showIndependentlyOnResolution }) =>
        !$showIndependentlyOnResolution
            ? css`
                  ${underMobileSmall`
                display: none;
            `}
              `
            : ''}
`;

export const PromotionBar = ({ promotionBarContent }: { promotionBarContent: PromotionBarContent[] }) => {
    const promotionBarItems = useMemo(() => promotionBarContent.slice(0, 4), [promotionBarContent]);
    const ga = useGa();

    return (
        <PromoBarWrapper $itemsAmount={promotionBarContent.length}>
            {promotionBarItems.map(promoCard => (
                <PromoBarLink
                    data-testid={BellaTestIds.promotionBarItem}
                    key={promoCard.id}
                    to={promoCard.relativeUrl}
                    onClick={() => ga.homepage.trackPromotionBarClick(promoCard.trackingName)}
                >
                    <LongTitleWrapper $showIndependentlyOnResolution={promotionBarItems.length < 4}>
                        {promoCard.title}
                    </LongTitleWrapper>
                    <ShortTitleWrapper $hideIndependentlyOnResolution={promotionBarItems.length < 4}>
                        {promoCard.shortTitle}
                    </ShortTitleWrapper>
                </PromoBarLink>
            ))}
        </PromoBarWrapper>
    );
};
