import * as React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { forDesktop, isDesktop, forMidDesktop, forTablet, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import { button, header1 } from '@westwing/ui-kit/typography';
import { StateInterface } from '../../redux/types';
import HorizontalScrollableElement, { ScrollDirection } from '../ScrollableElement';
import {
    ProductInterface,
    SegmentFeatureCategory,
    SegmentFeatureInteractionType,
    WishlistTrackingFn,
} from 'AppShell/appshell_types';
import { getNewProductsUrl } from '../../urlService';
import useGa from '../../hooks/useGa';
import useBellaTranslator from '../../hooks/useTranslator';
import ProductSlider from '../Generic/Sliders/ProductSlider';
import useLazyImageReducer from '../../hooks/lazyImages';
import useElementIntersection from '../../hooks/useElementIntersection';
import { lazyImageAction } from '../../hooks/lazyImages/actions';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';
import { productShadow } from '../Product/GenericProduct';
import { INTERSECTION_ACTION } from '../../hooks/lazyImages/types';
import GenericImpressionWrapper from 'Client/components/Generic/GenericImpressionWrapper';
import { useSegmentProductClickTracking } from '../../../common/segment/useSegmentProductClickTracking';
import { useSegmentProductListViewedTracking } from '../../../common/segment/useSegmentProductListViewedTracking';
import { SEGMENT_TRACKING_SOURCES } from '../../../common/segment/types';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import { getSegmentProductPayload } from 'Client/components/ListingPage';

const NewProductsSliderWrapper = styled(HorizontalScrollableElement)`
    color: ${({ theme }) => theme.colors.charcoal100};
    padding-top: 30px;
    padding-left: 40px;
    ${forMidDesktop`
        padding-left: 13px
    `}
`;

const CTA = styled.span`
    ${button}
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.charcoal100};
    text-decoration: underline;
`;

const StyledSpan = styled.span`
    ${header1}
    text-align: center;
    color: ${({ theme }) => theme.colors.charcoal100};
`;

const StyledProductSlider = styled(ProductSlider)`
    padding: 0 10px 0 40px;
    ${({ theme }) =>
        theme.featureToggles.p100FreistellerImages &&
        css`
            grid-template-rows: none;
        `}

    ${forMidDesktop`
        padding: 0;
    `}
`;

const NewProductsCard = styled(BellaHybridLink)`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.ivorey};
    width: 100%;
    height: 100%;
    margin-right: 20px;
    padding: 0.5em;

    ${({ theme }) =>
        theme.featureToggles.p100FreistellerImages &&
        css`
            height: 233px;

            ${forTablet`
                height: 266px;
            `}

            ${forMidDesktop`
                height: 284px;
            `}

            ${forMidWideScreens`
                height: 388px;
            `}
        `}

    :hover {
        ${productShadow}
    }
    ${forDesktop`
        margin-right: 40px;
    `}
`;

const defaultSegmentData: FeatureImpressionTracking = {
    feature_category: SegmentFeatureCategory.Slider,
    feature_name: 'New Products',
};

const NewProductsSeeMoreTile: React.FC<{ onClickAction: () => void }> = ({ onClickAction }) => {
    const tileElement = React.useRef<HTMLDivElement>(null);
    const t = useBellaTranslator();
    const [, dispatch] = useLazyImageReducer({
        isLoaded: false,
        hasComeIntoView: false,
        showSpinner: false,
    });

    useElementIntersection(tileElement, () => {
        dispatch(lazyImageAction(INTERSECTION_ACTION, true));
    });

    return (
        <NewProductsCard to={getNewProductsUrl()} onClick={onClickAction}>
            <StyledSpan ref={tileElement as any}>{t('New products this week')}</StyledSpan>
            <CTA>{t('See all')}</CTA>
        </NewProductsCard>
    );
};

const NewProductsSlider = () => {
    const t = useBellaTranslator();
    const gaTracking = useGa();
    const { trackFeatureInteractions, trackFeatureImpression } = useSegmentFeatureTracking();
    const trackSegmentProductClick = useSegmentProductClickTracking(SEGMENT_TRACKING_SOURCES.NEW_PRODUCT_SLIDER);
    const trackSegmentListingPageView = useSegmentProductListViewedTracking(
        SEGMENT_TRACKING_SOURCES.NEW_PRODUCT_SLIDER
    );

    const newProducts = useSelector<StateInterface, ProductInterface[]>(state => state.newProducts.products);

    const CTATrackingFn = React.useCallback(() => {
        gaTracking.homepage.trackNewProductsSeeAllTopBtnClick();
        trackFeatureInteractions({
            ...defaultSegmentData,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: 'See All',
        });
    }, [gaTracking, trackFeatureInteractions]);

    const clickTrackingFn = React.useCallback(
        (product: ProductInterface) => {
            trackSegmentProductClick(product);

            gaTracking.homepage.trackNewProductSliderClick();
        },
        [gaTracking, trackSegmentProductClick]
    );

    const wishListTrackingFn: WishlistTrackingFn = React.useCallback(
        ({ action, sku }) => {
            gaTracking.trackWishListHeartClick({
                action,
                label: `New_products_slider,${sku}`,
                clickSource: 'New Product Slider',
                sku,
            });
        },
        [gaTracking]
    );

    const lastTileTrackingFn = React.useCallback(() => {
        gaTracking.homepage.trackNewProductsSeeAllLastTileClick();
        trackFeatureInteractions({
            ...defaultSegmentData,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: 'See All',
        });
    }, [gaTracking, trackFeatureInteractions]);

    const ctaOptions = {
        text: t('See all'),
        link: getNewProductsUrl(),
        trackingFn: CTATrackingFn,
    };

    const newProductsWithTracking = React.useMemo(
        () =>
            newProducts.map(product => ({
                ...product,
                clickTrackingFn: () => clickTrackingFn(product),
                wishListTrackingFn,
            })),
        [newProducts, clickTrackingFn, wishListTrackingFn]
    );

    const sliderTrackLabel = 'New_products';
    const trackArrowClicks = (direction: ScrollDirection) => {
        if (isDesktop()) {
            gaTracking.homepage.trackSliderArrowComponentClick(sliderTrackLabel);
            trackFeatureInteractions({
                ...defaultSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: direction === Direction.Left ? 'Left Arrow' : 'Right Arrow',
            });
        }
    };

    const impressionAction = React.useCallback(() => {
        trackSegmentListingPageView({
            products: newProducts.map(getSegmentProductPayload),
        });
        gaTracking.homepage.trackSliderImpression('New products slider impression');
        trackFeatureImpression(defaultSegmentData);
    }, [gaTracking.homepage, trackFeatureImpression, trackSegmentListingPageView, newProducts]);

    return (
        <GenericImpressionWrapper action={impressionAction}>
            <NewProductsSliderWrapper
                title={t('New products this week')}
                ctaOptions={ctaOptions}
                arrowClickTrackFunction={trackArrowClicks}
                showNavArrows
                showHeader
            >
                <StyledProductSlider products={newProductsWithTracking} hideNewProductBadge>
                    <NewProductsSeeMoreTile onClickAction={lastTileTrackingFn} />
                </StyledProductSlider>
            </NewProductsSliderWrapper>
        </GenericImpressionWrapper>
    );
};
export default NewProductsSlider;
