import useGa from 'Client/hooks/useGa';
import { HomepageEventCategory } from 'Common/ga/homepage';
import { ProductInterface, WishlistTrackingFn } from '../../../../appshell/appshell_types';
import { useSegmentProductClickTracking } from '../../../../common/segment/useSegmentProductClickTracking';

const action = 'Single Look Promotion';

const useTracking = (lookName: string, source: string) => {
    const gaTracking = useGa();
    const trackSegmentProductClick = useSegmentProductClickTracking(source);

    const trackImpression = () => gaTracking.homepage.trackSliderImpression(`Single Look promotion, Look: ${lookName}`);
    const trackCartIconClick = () =>
        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `Cart Icon - Click, Look: ${lookName}`,
        });
    const trackImageClick = () =>
        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `Image - Click, Look: ${lookName}`,
        });
    const trackHotspotClick = (productSku: string) =>
        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `Hotspot - Click, Look: ${lookName}, Product: ${productSku}`,
        });
    const trackProductOverlayClick = (productSku: string) =>
        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `Product overlay - Click, Look: ${lookName}, Product: ${productSku}`,
        });

    const trackProductClick = (product: ProductInterface) => {
        trackSegmentProductClick(product);

        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `Product - Click, Look: ${lookName}, Product: ${product.sku}`,
        });
    };
    const trackCTAClick = () =>
        gaTracking.trackGenericEvent(HomepageEventCategory, {
            action,
            label: `CTA - Click, Look: ${lookName}`,
        });
    const trackWishlistClick: WishlistTrackingFn = ({ action, sku }) => {
        gaTracking.trackWishListHeartClick({
            action,
            clickSource: 'Shop the Look Hotspots',
            sku,
        });
    };

    const trackProductGridWishlistClick: WishlistTrackingFn = ({ action, sku }) => {
        gaTracking.trackWishListHeartClick({
            action,
            clickSource: 'Shop the Look Promo',
            sku,
        });
    };

    return {
        trackImpression,
        trackCartIconClick,
        trackImageClick,
        trackHotspotClick,
        trackProductOverlayClick,
        trackProductClick,
        trackCTAClick,
        trackWishlistClick,
        trackProductGridWishlistClick,
    };
};

export default useTracking;
