import React, { FC } from 'react';
import styled from 'styled-components';
import { forTablet, forWideScreens, forDesktop } from '@westwing/ui-kit/breakPoints';
import { BellaTestIds } from '../../../common/qaIds';
import useGa from '../../hooks/useGa';
import BubblesSlider, { CenteredBubblesSliderWrapper } from '../Generic/BubblesSlider';
import { NavigationBaseData } from '../Generic/NavigationItem';
import { HomepageTrackingActions } from '../../../common/ga/types';

interface CategoriesBubbleSliderProps {
    categories: NavigationBaseData[];
    isBottom?: boolean;
}

const HomepageBubbleSliderBase = styled(BubblesSlider)`
    margin: 15px 0 8px;

    ${forTablet`
        margin: 15px;
    `}
`;

const HomepageBubbleSliderTop = styled(HomepageBubbleSliderBase)`
    ${forDesktop`
        display: none;
    `}
`;

const HomepageBubbleSliderBottom = styled(HomepageBubbleSliderBase)`
    ${forDesktop`
        margin: 15px 40px 0;
    `}
    ${forWideScreens`
        margin: 15px auto 0;
    `}
`;

const CategoriesBubbleSlider: FC<CategoriesBubbleSliderProps> = ({ categories, isBottom }) => {
    const gaTracking = useGa();

    const trackHomeCrossCategoryBubble = (categoryName: string) => {
        const action = isBottom
            ? HomepageTrackingActions.BottomCrossCategoryClick
            : HomepageTrackingActions.CrossCategoryBubbleClick;
        gaTracking.homepage.trackCrossCategoryBubbleClick({ action, label: categoryName });
    };

    const HomepageBubbleSlider = isBottom ? HomepageBubbleSliderBottom : HomepageBubbleSliderTop;

    return isBottom ? (
        <CenteredBubblesSliderWrapper>
            <HomepageBubbleSlider
                bubbles={categories}
                onItemClick={trackHomeCrossCategoryBubble}
                testId={BellaTestIds.categoriesBubblesSlider}
            />
        </CenteredBubblesSliderWrapper>
    ) : (
        <HomepageBubbleSlider
            bubbles={categories}
            onItemClick={trackHomeCrossCategoryBubble}
            testId={BellaTestIds.categoriesBubblesSlider}
        />
    );
};

export default React.memo(CategoriesBubbleSlider);
