import React, { useCallback } from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { AspectRatio } from '@westwing/ui-kit/AspectRatio';
import { PortraitBanner } from '../../redux/homepage/types';
import Banner from '../Generic/Banner';
import useGa from '../../hooks/useGa';
import { homePagePortraitBannerRatios } from '../../constants/aspectRatios';
import { BannerText } from './SecondaryBanner';
import { BannerTrackingVariations } from 'Common/ga/homepage';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

interface PortraitBannersProps {
    portraitBanners: PortraitBanner[];
}

const StyledWrapper = styled.div`
    padding: 25px 20px 0;
    ${forDesktop`
        padding: 25px 20px 0;
    `}
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const StyledContainer = styled.div`
    width: 100%;
    ${forDesktop`
          width: calc((100% - 20px) / 3);
    `}
`;

const StyledBannerText = styled(BannerText)`
    padding-top: 5px;
`;

const StyledBanner = styled(Banner)`
    ${AspectRatio}
`;

const PortraitBanners: React.FC<PortraitBannersProps> = ({ portraitBanners = [] }) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const getTrackingFn = (id: string, index: number) => () => {
        const offset = 3; // we are tracking Homepage Banner 3-5 here
        const bannerIndex = `${offset + index}`;
        trackFeatureInteractions({
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: '',
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: `Homepage ${bannerIndex}`,
            feature_label: id,
        });
        gaTracking.homepage.getBannerTrackingFn(BannerTrackingVariations.General, id)();
    };

    const handleBannerImpression = useCallback(
        (trackingName: string, bannerNumber) => {
            gaTracking.homepage.trackBannerImpression(trackingName);

            trackFeatureImpression({
                feature_category: SegmentFeatureCategory.Banner,
                feature_name: `Homepage ${bannerNumber + 3}`,
                feature_label: trackingName,
            });
        },
        [gaTracking.homepage, trackFeatureImpression]
    );

    return (
        <StyledWrapper>
            {portraitBanners.map((banner, index) => (
                <StyledContainer key={banner.title}>
                    <StyledBanner
                        ratio={homePagePortraitBannerRatios}
                        desktopImage={banner.image}
                        mobileImage=""
                        targetUrl={banner.targetUrl}
                        trackingFn={getTrackingFn(banner.trackingName, index)}
                        trackImpressionFunction={() => handleBannerImpression(banner.trackingName, index)}
                    />
                    <StyledBannerText {...banner} />
                </StyledContainer>
            ))}
        </StyledWrapper>
    );
};

export default PortraitBanners;
