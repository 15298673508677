import React, { useRef } from 'react';
import styled from 'styled-components';
import { forTablet, forWideScreens, forSemiWideScreens, forDesktop, forMidDesktop } from '@westwing/ui-kit/breakPoints';
import { textLinesLimitClipCss } from '@westwing/ui-kit/typography';
import { BannerTitleLink } from '../BannerTitle';
import Product from '../../Product/GenericProduct';
import useTracking from './useTracking';
import LookInteractiveImage from 'Client/components/Looks/LooksDetailPage/LookInteractiveImage';
import { BroadBorderProps } from 'Client/components/Looks/LooksDetailPage/LooksDetailPageElements';
import { Header, Title } from 'Client/components/ScrollableElement';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { useBellaDispatch, useBellaSelector } from 'Client/redux/hooks';
import useInView from 'Client/hooks/dom/useInView';
import { BellaTestIds } from 'Common/qaIds';
import { ProductInterface } from 'AppShell/appshell_types';
import { PromotedLook } from 'Client/redux/homepage/types';
import { setLDPKnownInfoAction } from 'Client/redux/lookDetail/actions';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    ${forDesktop`
        padding-bottom: 40px;
    `}
`;

const StyledHeader = styled(Header)`
    padding: 24px 20px 16px;
    margin: 0px;

    ${forTablet`
        padding-top: 30px;
    `}

    ${forMidDesktop`
        padding-top: 20px;
    `}
`;

const StyledImageWrapper = styled.div`
    grid-column: 1/3;
    padding-left: 20px;
    padding-bottom: 20px;

    ${forTablet`
        [data-orientation='portrait'] & {
            grid-column: 1;
        }
        [data-orientation='landscape'] & {
            padding-right: 20px;
        }
    `}
`;

// 41% is the magic number so the image is big enough to fill all the height.
// It works if bigger, but the overall size of the section will increase too
const MIN_IMAGE_WIDTH = '41%';

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));

    ${forSemiWideScreens`
        grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));

        [data-orientation='portrait'] & {

            grid-template-columns: minmax(${MIN_IMAGE_WIDTH}, 1fr) repeat(2, minmax(0, 1fr));
        }
    `}
`;

const StyledProductsGridWrapper = styled.div`
    display: none;

    ${forSemiWideScreens`
        [data-orientation='portrait'] & {
            display: grid;
            grid-template-columns: repeat(2, 148px);
            grid-template-rows: repeat(2, 1fr);
            gap: 10px;
            justify-content: left;
            padding-left: 16px;
        }
    `}

    ${forWideScreens`
        [data-orientation='portrait'] & {
            grid-template-columns: repeat(2, 192px);
        }
    `}
`;

const StyledLookTextWrapper = styled.div`
    display: none;

    ${forTablet`
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 55px;

        [data-orientation='portrait'] & {
            display: flex;
        }
    `}

    ${forSemiWideScreens`
        display: flex;
        padding: 0 80px;
    `}
`;

const StyledLookWrapper = styled.div`
    position: relative;
    margin-left: 20px;

    [data-orientation='portrait'] & {
        width: calc(100% - 40px);
    }
    [data-orientation='landscape'] & {
        width: calc(100% - 20px);
    }
`;

const StyledBannerText = styled(BannerTitleLink)`
    padding-top: 16px;
    max-width: 280px;
    padding-bottom: 0px;

    ${forSemiWideScreens`
        display: none;
    `}
`;

const StyledDecription = styled(BannerTitleLink)`
    display: none;

    ${forTablet`
        max-width: 100%;

        h2 {
            ${textLinesLimitClipCss(2)}
            white-space: normal;
            font-size: 26px;
            margin-bottom: 17px;
            line-height: 31px;
        }

        p {
            ${textLinesLimitClipCss(7)}
        }
    `}

    [data-orientation='portrait'] & {
        width: calc(100% - 40px);
    }

    ${forTablet`
        [data-orientation='portrait'] & {
            display: block;
        }
    `}

    ${forSemiWideScreens`
        display: block;
    `}
`;

const ImageBackdrop = styled.div<BroadBorderProps>`
    background: ${({ borderColor }) => borderColor};
    bottom: -20px;
    height: calc(100% - 20px);
    position: absolute;
    left: -20px;
    width: calc(100% + 20px);
    z-index: -1;

    [data-orientation='landscape'] & {
        width: calc(100%);
    }

    ${forTablet`
        [data-orientation='landscape'] & {
            width: calc(50%);
        }
    `}
`;

const LooksSlider: React.FC<{ promotedLook: PromotedLook }> = ({ promotedLook }) => {
    const t = useBellaTranslator();
    const dispatch = useBellaDispatch();

    const containerRef = useRef<HTMLDivElement>(null);

    const { isFetching } = useBellaSelector(state => state.homepage);

    const {
        trackImpression,
        trackCartIconClick,
        trackImageClick,
        trackHotspotClick,
        trackProductOverlayClick,
        trackProductClick,
        trackCTAClick,
        trackWishlistClick,
        trackProductGridWishlistClick,
    } = useTracking(promotedLook.trackingName || promotedLook.name || '', 'Shop the Look Promo');

    const isElementInView = useInView<HTMLDivElement>({
        containerRef,
        options: { threshold: 0.8 },
        onVisibleCallback: trackImpression,
    });

    const orientation = promotedLook.isLandscape ? 'landscape' : 'portrait';

    const handleShopTheLookCTAClick = () => {
        trackCTAClick();
        dispatch(setLDPKnownInfoAction(promotedLook));
    };

    return (
        <StyledContainer
            data-orientation={orientation}
            ref={containerRef}
            data-testid={BellaTestIds.homepageSingleLookPromotion}
        >
            <StyledHeader data-testid={BellaTestIds.homepageSingleLookPromotionTitle}>
                <Title>{t('Shop_the_Look_Promotion_Headline')}</Title>
            </StyledHeader>
            <ContentWrapper>
                <StyledImageWrapper>
                    <StyledLookWrapper>
                        <LookInteractiveImage
                            look={promotedLook}
                            isFetching={isFetching}
                            backdropComponent={ImageBackdrop}
                            preventAutoScroll={!isElementInView}
                            onHotSpotClick={trackHotspotClick}
                            onImageClick={trackImageClick}
                            onProductOverlayClick={trackProductOverlayClick}
                            onCartButtonClick={trackCartIconClick}
                            trackWishlistBadgeHotspotClick={trackWishlistClick}
                            data-testid={BellaTestIds.homepageSingleLookPromotionImage}
                        />
                    </StyledLookWrapper>
                    <StyledBannerText
                        title={promotedLook.name}
                        ctaText={t('Shop_the_look_homepage_promotion_CTA')}
                        targetUrl={`/looks/${promotedLook.slug}/`}
                        onClick={handleShopTheLookCTAClick}
                        data-testid={BellaTestIds.homepageSingleLookPromotionCta}
                    />
                </StyledImageWrapper>
                <StyledProductsGridWrapper>
                    {promotedLook.products &&
                        promotedLook.products.slice(0, 4).map((product: ProductInterface) => {
                            const productWithTracking = {
                                ...product,
                                clickTrackingFn: () => trackProductClick(product),
                                wishListTrackingFn: product.wishListTrackingFn ?? trackProductGridWishlistClick,
                            };
                            return (
                                <Product
                                    key={product.simpleSku}
                                    product={productWithTracking}
                                    data-testid={BellaTestIds.homepageSingleLookPromotionProduct}
                                    forSingleLookPromotion
                                />
                            );
                        })}
                </StyledProductsGridWrapper>
                <StyledLookTextWrapper>
                    <StyledDecription
                        title={promotedLook.name}
                        subtitle={promotedLook.caption}
                        ctaText={t('Shop_the_look_homepage_promotion_CTA')}
                        targetUrl={`/looks/${promotedLook.slug}/`}
                        onClick={handleShopTheLookCTAClick}
                        data-testid={BellaTestIds.homepageSingleLookPromotionCtaDesktop}
                    />
                </StyledLookTextWrapper>
            </ContentWrapper>
        </StyledContainer>
    );
};

export default LooksSlider;
